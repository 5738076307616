import { benefitsList } from '../api/benefits/benefitsList.api';
import { handleError } from '../contexts/ErrorHandler/errorHandlerPropsMap';
import { PlanStatus } from '@wix/ambassador-pricing-plan-benefits-server/types';
import { IHttpClient } from '@wix/yoshi-flow-editor';

export const loadUserPaidPlans = async (httpClient: IHttpClient) => {
  try {
    const paidPlansWithBenefits = (await httpClient.request(benefitsList()))
      ?.data;

    const paidPlansWithBenefitsActive = paidPlansWithBenefits.benefits.filter(
      ({ planInfo }) => planInfo.status === PlanStatus.ACTIVE,
    );

    return paidPlansWithBenefitsActive.length
      ? paidPlansWithBenefitsActive.map(({ planInfo, benefit }) => {
          const plan = paidPlansWithBenefits.paidPlans.find(({ id }) => {
            return id === planInfo.id;
          });

          return {
            id: planInfo.id,
            details: {
              id: planInfo.id,
              name: planInfo.name,
              description: plan?.description || '',
            },
            challenges: benefit.resourceIds,
            paymentOptions: {
              price: {
                amount: plan?.pricing?.price?.value,
                currency: plan?.pricing?.price?.currency,
              },
              recurring: !!plan?.pricing.subscription,
              validFor: {
                forever: false,
                period: {
                  amount: plan?.pricing?.subscription?.cycleDuration?.count,
                  unit: plan?.pricing?.subscription?.cycleDuration?.unit,
                },
              },
            },
            visible: planInfo.visible || false,
          };
        })
      : [];
  } catch (error) {
    handleError({ error, context: 'loadUserPaidPlans' });
  }
};
