import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { getChallengeSlugFromLocation } from '../Location/locationProviderPropsMap';
import { SelectedPaymentOption } from '../../components/ChallengesPage/Widget/components/Pricing/interfaces';

import userTypeHandlers from './helpers/userTypeHandlers';
import { getParticipant } from './helpers/getParticipant';
import { joinToChallenge } from './helpers/joinUserToChallenge';
import { cancelInvite } from './helpers/cancelInvite';
import { leaveTheChallenge } from './helpers/leaveTheChallenge';

import { IUserProviderProps } from './UserProvider';
import {
  getUserFromConfig,
  getUserType,
  handleUserLogin,
  promptLogin,
  UpdatedUserData,
  updateUserContext,
} from './helpers/userContextHelpers';

import memoize from 'lodash/memoize';
import cloneDeep from 'lodash/cloneDeep';
import { isMA } from '../../selectors/isMA';

const callbacks: ((api: ControllerFlowAPI) => any)[] = [];

export function onParticipantLogin(callback: (api: ControllerFlowAPI) => any) {
  callbacks.push(callback);
}

export const userProviderPropsMap = memoize(async function (
  flowAPI: ControllerFlowAPI,
): Promise<IUserProviderProps> {
  const user = getUserFromConfig(flowAPI.controllerConfig);
  const slug = getChallengeSlugFromLocation(flowAPI);
  // participant will only appear on url with challengeId or slug;
  const participant =
    slug && !isMA(flowAPI) ? await getParticipant(flowAPI) : null;

  const userProviderProps: IUserProviderProps = {
    user,
    userType: getUserType(user, participant),
    participant,
    async promptLogin(): Promise<any> {
      return promptLogin(flowAPI);
    },
    async join(
      selectedPaymentOption: SelectedPaymentOption,
      startDate?: string,
    ): Promise<any> {
      return joinToChallenge(
        flowAPI,
        userProviderProps,
        selectedPaymentOption,
        startDate,
      );
    },
    userTypeHandlers,
    async cancelJoinRequest(challengeId?: string): Promise<void> {
      return cancelInvite(flowAPI, userProviderProps, challengeId);
    },
    async leaveTheChallenge(
      participantId: string,
      challengeId?: string,
    ): Promise<void> {
      return leaveTheChallenge(
        flowAPI,
        participantId,
        userProviderProps,
        challengeId,
      );
    },
    async incrementParticipantsCompletedStepSummary(): Promise<UpdatedUserData> {
      const participantCopy = cloneDeep(userProviderProps.participant);
      if (participantCopy?.stepsSummary) {
        participantCopy.stepsSummary.completedStepsNumber =
          participantCopy.stepsSummary.completedStepsNumber + 1;
      }
      return userProviderProps.updateParticipant(participantCopy);
    },
    async updateParticipant(newParticipant): Promise<UpdatedUserData> {
      const userData: Partial<IUserProviderProps> = await updateUserContext(
        flowAPI,
        newParticipant,
      );
      const isUserCurrentlyLoggedIn = userProviderProps.user.loggedIn;

      Object.entries(userData).forEach(([key, val]) => {
        userProviderProps[key] = val;
      });

      flowAPI.controllerConfig.setProps(userData);

      if (
        !isUserCurrentlyLoggedIn &&
        userData.user.loggedIn &&
        userData.participant
      ) {
        callbacks.forEach((callback) => callback(flowAPI));
      }

      return userData;
    },
  };

  await handleUserLogin(flowAPI, userProviderProps);

  return userProviderProps;
});
