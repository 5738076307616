import { StyleParamType, createStylesParams } from '@wix/tpa-settings';

import { IChallengeStyles } from './challengeStyles.types';

export const challengeStyles = createStylesParams<IChallengeStyles>({
  mobContentDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 4,
  },
  mobChallengeNameSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 28,
  },
  mobDateSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobPriceSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobSectionTitleSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 24,
  },
  mobCounterNumberSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobCounterDescriptionSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobDescriptionSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobButtonSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobAgendaSectionTitleSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobAgendaStepNameSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobAgendaLinkSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobListLayoutChallengeNameFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 24,
  },
  mobChallengeStatsFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobListLayoutHeaderMenuFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobListLayoutSectionNameFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 18,
  },
  mobListLayoutStepNameFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobListLayoutStepDescriptionFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobListLayoutOverviewDescriptionTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobSidebarLayoutSidebarChallengeNameFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 24,
  },
  mobSidebarLayoutSidebarSectionNameFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 18,
  },
  mobSidebarLayoutSidebarStepNameFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobSidebarLayoutStepDescriptionFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobSidebarLayoutBodyStepNameFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobSidebarLayoutOverviewDescriptionFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
});
