import userTypeHandlers from '../contexts/User/helpers/userTypeHandlers';
import {
  State as ParticipantState,
  Participant,
} from '@wix/ambassador-challenges-v1-participant/types';
import { IUserType } from '../contexts/User/UserContext';

export const isParticipantPage = ({
  participant = {},
  userType,
}: {
  participant: Participant;
  userType: IUserType;
}) => {
  return participant?.id && userTypeHandlers.isJoinedAlready(userType);
};

export function getOrderId(participant: Participant): string {
  if (participant) {
    switch (participant.transitions?.[0]?.state) {
      case ParticipantState.PAYMENT_STARTED:
        return participant.orderIds?.[0];
      default:
      case ParticipantState.PAYMENT_REQUESTED:
        return '';
    }
  }
  return '';
}
